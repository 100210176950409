import { Controller } from "@hotwired/stimulus"

// Add data-preview-wrapper-selector="#preview_wrapper" to where you set the data-controller="preview"
// Add a <template data-preview-target="template"> with the HTML you want to preview
// Add data-preview-target="source" and data-preview-placeholder="[abc]" to data sources
// Trigger with data-action="preview#refresh"
// Placeholders like '[abc]' will be replaced with the value on the 'source' element
// The element defined by the wrapper selector value (#preview_wrapper) will then be updated

export default class extends Controller {
  static targets = ["source", "template"]
  static values = { wrapperSelector: String }

  refresh() {
    if (!this.hasSourceTarget) { return }
    if (!this.hasTemplateTarget) { return }

    const previewWrapper = document.querySelector(this.wrapperSelectorValue)
    previewWrapper.innerHTML = this.buildTemplate()
  }

  buildTemplate() {
    let template = this.templateTarget.innerHTML
    this.sourceTargets.forEach((sourceElement) => {
      const placeholder = sourceElement.dataset.previewPlaceholder
      let value = ''
  
      if (sourceElement instanceof HTMLInputElement || sourceElement.tagName.toLowerCase() === 'textarea') {
        value = sourceElement.value
      } else if (sourceElement.tagName.toLowerCase() === 'trix-editor') {
        const inputId = sourceElement.getAttribute('input')
        const hiddenInput = document.getElementById(inputId)
        value = hiddenInput?.value || ''
      }
      // NOTE: Add additioal conditions when required
  
      if (placeholder && value) {
        value = value.replace(/"/g, '&quot;');
        template = template.replaceAll(placeholder, value)
      }
    });
  
    return template
  }
}
