import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["section"];

  connect() {
    if (window.innerWidth < 1024) {
      // On smaller devices, scroll the current navigation button into view
      this.scrollToCurrentSection();
    }
  }

  scrollToCurrentSection() {
    const currentSection = this.sectionTargets.find(section =>
      section.classList.contains("current")
    );

    if (currentSection) {
      const container = this.element;
      const sectionOffsetLeft = currentSection.offsetLeft;
      const sectionWidth = currentSection.offsetWidth;
      const containerWidth = container.offsetWidth;

      const newScrollLeft = sectionOffsetLeft - (containerWidth / 2) + (sectionWidth / 2);

      container.scrollTo({
        left: newScrollLeft,
        behavior: "smooth"
      });
    }
  }
}
