import { Controller } from "@hotwired/stimulus"
export default class extends Controller {
  static targets = ["form", "listInput", "column"]

  connect() {
    this.columnTargets.forEach(column => {
      this.updateItemsNumber(column)
    });
  }

  update(e) {
    const el = e.target.closest(".climate-board-item");
    const statusValue = e.target.value
    const form = el.querySelector(".list-item-form");
    const statusInput = el.querySelector(".list-item-status");

    statusInput.value = statusValue
    form.requestSubmit();
  }

  updateItemsNumber(column) {
    const items = column.querySelectorAll(".climate-board-item");
    const container = column.closest(".climate-board-column-container");
    const statusElement = container.querySelector(".climate-board-column-header__status")
    statusElement.textContent = items.length
  }
}
