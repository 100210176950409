import { Controller } from '@hotwired/stimulus'
import { useClickOutside } from 'stimulus-use'
import { debounce, get } from 'lodash-es'

export default class extends Controller {

  static targets = ['input', 'searchInput', 'results']
  static values = {
    url: String,
    minSearchQuery: { type: Number, default: 3 }
  }

  connect() {
    if (this.hasResultsTarget) {
      useClickOutside(this, { element: this.resultsTarget })
    } else {
      console.error('no autocomplete results target defined')
    }
    this.debouncedSearchHandler = debounce(this.search.bind(this), 300)
    this.searchInputTarget.addEventListener('input', this.debouncedSearchHandler)
    this.searchInputTarget.addEventListener('focus', this.debouncedSearchHandler)
  }

  search(e) {
    if (!this.hasResultsTarget) {
      console.error('no autocomplete results target defined')
      return
    }
    this.postionResultsTarget()
    if (this.urlValue.length === 0) {
      return
    }
    const searchQuery = get(this, 'searchInputTarget.value', '')
    if (searchQuery.length < this.minSearchQueryValue) {
      return
    }

    let autocompleteUrl = new URL(this.urlValue)
    let autocompleteParams = new URLSearchParams(autocompleteUrl.search)
    autocompleteParams.set('q', searchQuery)
    autocompleteUrl.search = autocompleteParams
    this.resultsTarget.addEventListener('turbo:frame-load', this.showResults.bind(this), { once: true })
    this.resultsTarget.setAttribute('src', autocompleteUrl.toString())
  }

  onSelect(e) {
    let data = JSON.parse(get(e, 'currentTarget.dataset.detail', '{}'))
    this.populateInputs(data)
    this.hideResults()
    let event = new CustomEvent('autocomplete:result-selected', { detail: data })
    window.dispatchEvent(event)
  }

  populateInputs(data) {
    this.populateInput(this.searchInputTarget, data)
    this.inputTargets.forEach(element => {
      this.populateInput(element, data)
    })
  }

  populateInput(element, data) {
    const key = get(element, 'dataset.autocompleteKey')
    if (!key) {
      console.error(`unable to populate input with no autocomplete-key ${element.getAttribute("name")}`)
      return
    }
    const value = get(data, key, '')
    element.value = value
  }

  hideResults() {
    this.resultsTarget.classList.remove('active')
  }

  showResults() {
    this.resultsTarget.classList.add('active')
  }

  postionResultsTarget() {
    const parent = this.searchInputTarget.closest('.autocomplete-search')
    const label = parent.querySelector('label')

    const rect = this.searchInputTarget.getBoundingClientRect()
    const labelRect = label.getBoundingClientRect()
    const labelStyle = getComputedStyle(label)
    const style = getComputedStyle(this.searchInputTarget)
    const labelHeight = labelRect.height + parseInt(labelStyle.marginTop) + parseInt(labelStyle.marginBottom)
    const marginWidth = parseInt(style.marginLeft)

    this.resultsTarget.style.top = `${rect.height + labelHeight}px`
    this.resultsTarget.style.left = `${marginWidth}px`
    this.resultsTarget.style.width = `${rect.width}px`

    parent.append(this.resultsTarget)
  }

  clickOutside(event) {
    this.hideResults()
  }

}
