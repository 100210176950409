import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    this.initializeExpectedAnswers();
    this.initializeAttachmentModes();
  }

  showAttachments(e) {
    const container = e.currentTarget;
    // if attachment is added and page resets due to validation error the container (.show-attachment toggle) will be null
    if (!container) return;

    const attachments = container
      .closest(".question-container")
      .querySelector(".attachments");
    const showAttachmentsLink = container
      .closest(".question-container")
      .querySelector(".show-attachments");

    if (attachments && showAttachmentsLink) {
      attachments.classList.remove("hide");
      showAttachmentsLink.style.display = "none";
    }
  }

  hideAttachments(container) {
    const attachments = container.querySelector(".attachments");
    const showAttachmentsLink = container.querySelector(".show-attachments");
    if (attachments && showAttachmentsLink) {
      attachments.classList.add("hide");
      showAttachmentsLink.style.display = "";
    }
  }

  hideAttachmentsIfNoUnexpectedAnswers(optionsElement) {
    const answers = Array.from(
      optionsElement.querySelectorAll(".answer:checked")
    );
    const allExpectedOrNotApplicable = answers.every((el) => {
      return (
        el.value == el.dataset.expected ||
        el.dataset.notApplicable !== undefined
      );
    });

    if (allExpectedOrNotApplicable) {
      this.hideAttachments(optionsElement.closest(".question-container"));
    }
  }

  setAttachmentMode(e) {
    const link = e.currentTarget;
    const mode = link.dataset.mode;
    const attachmentsContainer = link.closest(".attachments");

    if (!attachmentsContainer) return;

    attachmentsContainer
      .querySelectorAll("nav li")
      .forEach((li) => li.classList.remove("active"));
    attachmentsContainer
      .querySelectorAll("section")
      .forEach((section) => section.classList.add("hide"));

    link.closest("li").classList.add("active");
    const modeSection = attachmentsContainer.querySelector(`.add-${mode}`);
    if (modeSection) {
      modeSection.classList.remove("hide");
    }
  }

  processExpectedAnswer(answerInput) {
    const options = answerInput.closest(".answer-options");
    answerInput
      .closest(".choice")
      .parentNode.querySelectorAll(".choice")
      .forEach((choice) => choice.classList.remove("success", "alert"));

    if (answerInput.value == answerInput.dataset.expected) {
      answerInput.closest(".choice").classList.add("success");
      this.hideAttachmentsIfNoUnexpectedAnswers(options);
    } else if (answerInput.dataset.notApplicable !== undefined) {
      this.hideAttachmentsIfNoUnexpectedAnswers(options);
    } else {
      answerInput.closest(".choice").classList.add("alert");
      this.showAttachments({
        currentTarget: options
          .closest(".question-container")
          .querySelector(".show-attachments"),
      }); // Simulate event object
    }
  }

  initializeExpectedAnswers() {
    this.element.querySelectorAll("[data-expected]").forEach((el) => {
      el.addEventListener("change", (e) => {
        this.processExpectedAnswer(e.currentTarget);
      });
      const isChecked = el.checked;
      if (isChecked) {
        this.processExpectedAnswer(el);
      }
    });
  }

  initializeAttachmentModes() {
    this.element
      .querySelectorAll(".attachments:not(.hide)")
      .forEach((attachmentsElement) => {
        const firstNavLink = attachmentsElement.querySelector(
          "nav li a:not([data-blank])"
        );
        if (firstNavLink) {
          this.setAttachmentMode({ currentTarget: firstNavLink }); // Simulate event object
        }
      });
  }
}
