import { Controller } from "stimulus";

export default class extends Controller {

  connect() {
    // Scroll to target element where the controller is connected with buffer of 150px
    let scrollPosition = this.element.offsetTop + 150
    window.scrollTo({
      top: scrollPosition,
      behavior: "smooth",
    })
  }
}