import { Controller } from "@hotwired/stimulus";
import Muuri from "muuri";

export default class extends Controller {
  static targets = ["column"];

  connect() {
    const _this = this;
    var dragContainer = document.querySelector(".drag-container");
    var columnGrids = [];
    var boardGrid;
    var initialColumn;

    // Initialize the column grids so we can drag those items around.
    this.columnTargets.forEach(function (container) {
      var grid = new Muuri(container, {
        items: ".climate-board-item",
        dragEnabled: true,
        dragSort: function () {
          return columnGrids;
        },
        dragContainer: dragContainer,
        dragAutoScroll: {
          targets: (item) => {
            return [
              { element: window, priority: 0 },
              { element: item.getGrid().getElement().parentNode, priority: 1 },
            ];
          },
        },
        dragStartPredicate: {
          delay: 50,
          distance: 50
        },
        dragPlaceholder: {
          enabled: true,
          createElement: (item) => item.getElement().cloneNode(true),
        },
      })
        .on("dragInit", function (item) {
          item.getElement().style.width = item.getWidth() + "px";
          item.getElement().style.height = item.getHeight() + "px";
        })
        .on("dragStart", function (item) {
          initialColumn = item.getElement().parentNode;
        })
        .on("dragReleaseEnd", function (item) {
          item.getElement().style.width = "";
          item.getElement().style.height = "";
          item.getGrid().refreshItems([item]);
        })
        .on("layoutStart", function () {
          boardGrid.refreshItems().layout();
          _this.emptyColumn(grid);
        })
        .on("dragEnd", function (item) {
          const grid = item.getGrid();
          const el = item._element;
          const oldStatusValue = el.querySelector("#project_item_status");
          const oldPositionValue = Number(
            el.querySelector("#project_item_board_position").value
          );
          const newPositionValue =
            Number(Array.from(grid.getItems()).indexOf(item)) + 1; // 0 indexed array
          const newStatusValue = grid._element.getAttribute("data-id");

          if (
            newStatusValue != oldStatusValue ||
            newPositionValue != oldPositionValue
          ) {
            _this.updateItem(el, newStatusValue, newPositionValue);
          }
        });

      columnGrids.push(grid);
    });

    // Init board grid so we cannot drag those columns around.
    boardGrid = new Muuri(".climate-board", {
      dragEnabled: false,
    });
  }

  updateItem(item, statusValue, positionValue) {
    const form = item.querySelector("form");
    const positionInput = item.querySelector(".board-item-position");
    const statusInput = item.querySelector(".board-item-status");

    positionInput.value = positionValue;
    statusInput.value = statusValue;

    form.requestSubmit();
  }

  emptyColumn(grid) {
    const items = grid.getItems();
    if (items.length > 0) {
      grid.getElement().classList.remove("no-items");
    } else {
      grid.getElement().classList.add("no-items");
    }

    this.updateItemsNumber(grid.getElement(), items.length)
  }

  updateItemsNumber(grid, count) {
    const container = grid.closest(".climate-board-column-container");
    const statusElement = container.querySelector(".climate-board-column-header__status")
    statusElement.textContent = count
  }
}
