import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  toggleFollowUp(e) {
    e.preventDefault();
    const attachment = e.currentTarget.parentElement.querySelector('.attachments')
    attachment.classList.remove('hide')
    attachment.querySelector('.add-another-action-point').click()
    e.currentTarget.style.display = 'none'
  }
}
