import { Controller } from '@hotwired/stimulus'
import { requestSubmit } from '../../lib/request_submit'

export default class extends Controller {
  static targets = [
    'sort',
    'direction',
    'header',
    'submitButton',
    'form'
  ]

  initialize() {
    this.sortByThisColumnAndToggleOrder = this.sortByThisColumnAndToggleOrder.bind(this)

    this.nextSort = {
      'none': 'asc',
      'asc': 'desc',
      'desc': 'asc',
    }
  }

  sortByThisColumnAndToggleOrder(e) {
    // The data values from the <th> tag that was clicked
    let target = e.currentTarget
    let columnData = target.dataset

    this.headerTargets.forEach(el => {
      if (el !== target) {
        delete el.dataset.sort
      }
    })

    // Toggle between ascending and descending if the sort direction is set,
    // otherwise pick ascending
    columnData.sort = this.nextSort[columnData?.sort ?? 'none']

    this.sortTarget.value = columnData.column
    this.directionTarget.value = columnData.sort


    if (this.hasFormTarget) {
      requestSubmit(this.formTarget)
    } else {
      requestSubmit(this.element)
    }
  }
}
