import { Controller } from '@hotwired/stimulus'
import { debounce, get, partition } from 'lodash-es'

export default class extends Controller {
  static targets = ['input', 'item']

  connect() {
    this.autoSearch = debounce(this.search.bind(this), 300)
  }

  search(e) {
    let showItems, hideItems
    [showItems, hideItems] = this.filter(this.inputTarget.value)

    showItems.forEach(item => item.classList.remove('hide'))
    hideItems.forEach(item => item.classList.add('hide'))
  }

  filter(value) {
    value = value.toLowerCase().trim()
    if (value === '') {
      return [this.itemTargets, []]
    }
    return partition(this.itemTargets, (item) => { return get(item, 'dataset.value', '').toLowerCase().includes(value) })
  }


}