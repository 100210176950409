import { Controller } from "@hotwired/stimulus"
import { escapeRegExp, get } from "lodash-es"

export default class extends Controller {
  static targets = [
    "ticketForm",
    "assignedToInput",
    "statusInput",
    "priorityInput",
    "categoryInput",
    "findUserInput",
    "commentFormContainer",
    "commentForm",
    "comment",
    "commentFields",
    "commentStatusInput",
    "closeButton",
    "editDetails",
    "raiseJobContainer",
    "assetPicker",
    "addAssetButton"
  ]

  assignTicket(event) {
    this.closeDropdowns()
    let userId = event.currentTarget.dataset.userId
    let assignPath = event.currentTarget.dataset.path
    this.assignedToInputTarget.value = userId
    this.ticketFormTarget.action = assignPath
    this.ticketFormTarget.submit()
  }

  findUser(event) {
    const regex = new RegExp(".*\\b" + escapeRegExp(this.findUserInputTarget.value) + ".*", "gi")
    let userOptions = this.element.querySelectorAll(".user-option")
    let matchCount = 0
    if (this.findUserInputTarget.value.length > 0) {
      userOptions.forEach(function (elem, index) {
        if (elem.textContent.match(regex)) {
          elem.classList.remove("hide")
          matchCount += 1
        } else {
          elem.classList.add("hide")
        }
      })
    }
    if (matchCount == 0) {
      userOptions.forEach(function (elem, index) {
        elem.classList.add("hide")
      })
    }
  }

  setPriority(event) {
    let priorityId = event.currentTarget.dataset.priority
    if (this.priorityInputTarget.value == priorityId) {
      event.preventDefault()
      return false
    }
    this.priorityInputTarget.value = priorityId
    this.ticketFormTarget.submit()
  }

  setStatus(event) {
    this.closeDropdowns()
    let statusId = event.currentTarget.dataset.statusId
    this.statusInputTarget.value = statusId
    this.ticketFormTarget.submit()
  }

  setCategory(event) {
    let categoryId = event.currentTarget.dataset.categoryId
    if (this.categoryInputTarget.value == categoryId) {
      event.preventDefault()
      return false
    }
    this.categoryInputTarget.value = categoryId
    this.ticketFormTarget.submit()
  }


  updateButtonStatus(event) {
    const plainText = this.commentTarget.editor.getDocument().toString()
    if (plainText.trim() == "") {
      if (this.hasCloseButtonTarget) {
        this.closeButtonTarget.querySelector("span").textContent = "Resolve ticket"
      }
    } else {
      if (this.hasCloseButtonTarget) {
        this.closeButtonTarget.querySelector("span").textContent = "Comment & resolve ticket"
      }
    }
  }

  closeComment(event) {
    event.preventDefault()

    this.closeButtonTarget.disabled = true
    this.closeButtonTarget.querySelector("span").textContent = "Please wait..."

    if (this.commentTarget.value.trim().length > 0) {
      this.commentStatusInputTarget.value = "resolved"
      Rails.fire(this.commentFormTarget, 'submit')
    } else {
      this.statusInputTarget.value = "resolved"
      this.ticketFormTarget.submit()
    }
  }

  editDetails(event) {
    event.preventDefault()
    let [_response, _status, xhr] = event.detail

    this.editDetailsTarget.innerHTML = xhr.response
  }


  /*
   * Closes any open dropdowns.
   * This is a Zurb workaround see https://github.com/foundation/foundation-sites/blob/563a7342a4/js/foundation/foundation.dropdown.js#L95
   * Because any <a> in the dropdown content will close it, the user picker needs to be closed explicitly (because it can't have <a> tags).
   * This changes in zurb 6
   */
  closeDropdowns() {
    Foundation.libs.dropdown.close.call(Foundation.libs.dropdown, $(".f-dropdown.open[data-dropdown-content]"))
  }

  onCategorySelect(e) {
    if (!this.hasAssetPickerTarget) {
      return
    }

    const assetTypeIds = JSON.parse(get(e, "currentTarget.selectedOptions[0].dataset.assetTypeIds", "[]"))

    window.dispatchEvent(new CustomEvent('filter-asset-types', { detail: { ids: assetTypeIds } }))
  }

  updateAssetPickerStatus(e) {
    if (!this.hasAddAssetButtonTarget) {
      return
    }

    if (!this.hasAssetPickerTarget) {
      console.error('no asset picker target found')
      return
    }

    let categoryAssetsEnabled = false
    if (this.hasCategoryInputTarget) {
      if (this.categoryInputTarget.tagName.toLowerCase() == 'select') {
        categoryAssetsEnabled = get(this.categoryInputTarget, "selectedOptions[0].dataset.assetsEnabled", "false") == "true"
      } else {
        categoryAssetsEnabled = get(this.categoryInputTarget, "dataset.assetsEnabled", "false") == "true"
      }
    }
    const hasAssignedAssets = this.assetPickerTarget.querySelectorAll('.list-item[data-asset-instance-id]').length > 0

    const totalResults = parseInt(get(e, 'detail.total', 0))
    const filtered = get(e, 'detail.filtered', false)
    const assetSearchEnabled = categoryAssetsEnabled && (totalResults > 0 || filtered)

    const statusEl = this.addAssetButtonTarget.parentNode.querySelector('.status')
    if (assetSearchEnabled) {
      this.addAssetButtonTarget.disabled = false
      this.addAssetButtonTarget.classList.remove('hide')
      if (statusEl) {
        statusEl.classList.add('hide')
      }
    } else {
      this.addAssetButtonTarget.disabled = true
      this.addAssetButtonTarget.classList.add('hide')
      if (statusEl) {
        statusEl.classList.remove('hide')
      }
    }

    if (categoryAssetsEnabled || hasAssignedAssets) {
      this.assetPickerTarget.classList.remove('hide')
    } else {
      this.assetPickerTarget.classList.add('hide')
    }
  }
}
