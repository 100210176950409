import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "commentFormContainer",
    "raiseJobContainer"
  ]

  static values = {
    canViewPremises: Boolean
  }

  connect() {
    // Disable links if user doesn't have permission to view premises
    if (!this.canViewPremisesValue) {
      this.handleDisabledLinks();
    }
  }

  handleDisabledLinks() {
    const disabledLinks = document.querySelectorAll('.reactive-jobs-list a.disabled');
    disabledLinks.forEach(link => {
      link.addEventListener('click', (e) => {
        e.preventDefault();
      });
    });
  }

  showJobForm() {
    this.commentFormContainerTarget.classList.add("hide")
    this.raiseJobContainerTarget.classList.remove("hide")
  }

  cancelJobForm(event) {
    event.preventDefault()
    event.stopPropagation()

    this.commentFormContainerTarget.classList.remove("hide")
    this.raiseJobContainerTarget.classList.add("hide")
    this.raiseJobContainerTarget.querySelector("#job_form").innerHTML = ""
  }
}
