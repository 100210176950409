import { Controller } from "@hotwired/stimulus"
import Muuri from "muuri"

export default class extends Controller {
  static targets = ['toggleIcon', 'options']

  static values = {
    open: { type: Boolean, default: true }
  }

  connect() {
    this.grid = new Muuri('.news-grid', {
      dragEnabled: false,
      fillGaps: true,
    });


    this.grid.layout(true);
  }

  toggleOptions () {
    if (this.toggleIconTarget.classList.contains('fa-plus')) {
      this.toggleIconTarget.classList.remove('fa-plus')
      this.toggleIconTarget.classList.add('fa-minus')
      this.optionsTarget.classList.remove('hide');
      this.openValue = true
    } else {
      this.toggleIconTarget.classList.remove('fa-minus')
      this.toggleIconTarget.classList.add('fa-plus')
      this.openValue = false
      this.optionsTarget.classList.add('hide');
    }
  }

}
