import { Controller } from "@hotwired/stimulus"

// Add data-controller="iframe-resizer" to the iframe tag
// On load and resize, the iframe will resize to the size of the contents

export default class extends Controller {
  connect() {
    this.resizeIframe()
    window.addEventListener('resize', this.resizeIframe.bind(this))
  }

  resizeIframe() {
    const iframe = this.element
    if (iframe.contentWindow && iframe.contentWindow.document && iframe.contentWindow.document.body) {
      const default_buffer = 50
      iframe.style.height = iframe.contentWindow.document.body.scrollHeight + default_buffer + 'px'
    }
  }
}
