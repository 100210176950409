import { Controller } from '@hotwired/stimulus'
import { get } from 'lodash-es'

export default class extends Controller {

  static targets = ['usage', 'recordableType']
  static values = {
    refreshUrl: String
  }

  refreshForm(e) {
    const submitButton = this.element.querySelector('input[type=submit]')
    if (submitButton) {
      submitButton.classList.textContent = 'Please wait'
      submitButton.setAttribute('disabled', true)
    }
    if (this.hasRefreshUrlValue) {
      this.element.setAttribute('method', 'get')
      this.element.setAttribute('data-turbo-method', 'get')
      this.element.setAttribute('action', this.refreshUrlValue)
    }
    this.element.requestSubmit()
  }

  refreshEmission(e) {
    this.refreshForm()
  }

  refreshRecordable(e) {
    if (!this.hasRecordableTypeTarget) {
      console.error('unable to update form - no recordable type target found')
      return false
    }

    this.recordableTypeTarget.value = ''
    const selectedOpt = get(e.currentTarget, 'selectedOptions[0]')
    if (!selectedOpt) {
      console.error('no recordable option selected')
      return false
    }
    const recordableType = selectedOpt.dataset.recordableType
    this.recordableTypeTarget.value = recordableType

    this.refreshForm()
  }

}