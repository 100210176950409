import { Controller } from "@hotwired/stimulus";
import { debounce, includes, get } from "lodash-es";

export default class extends Controller {

	static get targets() {
		return [
			"accidentTypeDiv", "accidentTypeInput",
			"affectedPersonAddressDiv", "affectedPersonAddressInput",
			"affectedPersonNameDiv", "affectedPersonNameInput",
			"affectedPersonOccupationDiv", "affectedPersonOccupationInput",
			"affectedPersonTypeDiv", "affectedPersonTypeInput",
			"affectedPersonContactDiv", "affectedPersonContactInput",
			"affectedPersonOutcomeDiv", "affectedPersonOutcomeInput",
			"affectedPersonExternalInput",
			"contactedNextOfKinDiv", "contactedNextOfKinInput",
			"nextOfKinDetailsDiv", "nextOfKinDetailsInput",
			"contractorInvolvedDiv", "contractorInvolvedInput",
			"descriptionDiv", "descriptionInput",
			"injuryBodyPartDiv", "injuryBodyPartInput",
			"injuryDescriptionDiv", "injuryDescriptionInput",
			"injuryTypeDiv", "injuryTypeInput",
			"injuryOccurredDiv", "injuryOccurredInput",
			"peopleAffectedDiv", "peopleAffectedInput",
			"severityDiv", "severityInput",
			"treatmentDescriptionDiv", "treatmentDescriptionInput",
			"treatmentTypeDiv", "treatmentTypeInput",

			// Blocks
			'affectedPersonsFormBlock',
			'injuryFormBlock',
			'implicationsFormBlock',

			// Autocomplete
			'autocompleteResults'
		];
	}

	static values = {
		'staffAutocompleteUrl': String,
		'pupilAutocompleteUrl': String
	}

	connect() {
		this.update_field_visibility();
	}


	domShow(e) {
		e.classList.add('visible');
		e.classList.remove('hidden');
	}

	domHide(e) {
		e.classList.add('hidden');
		e.classList.remove('visible');
	}

	capitalizeFirstLetter(string) {
		return string.charAt(0).toUpperCase() + string.slice(1);
	}

	hide_fields(targets) {
		let self = this;
		targets.forEach(e => {
			if (self["has" + self.capitalizeFirstLetter(e) + "DivTarget"]) {
				self.domHide(self[e + "DivTarget"]);
				// Fields that can't be seen, shouldn't be submitted
				if (self[e + "InputTargets"].length > 1) {
					if (self[e + "InputTargets"].length === 2) {
						// Put yes/no questions back to no
						self[e + "InputTargets"].forEach(e => {
							e.checked = !(e.value == 'true');
						})
					}
					else {
						// Deselect answers to multiple choice questions
						self[e + "InputTargets"].forEach(e => e.checked = false)
					}
				}
				else {
					// Text boxes are reset
					self[e + "InputTarget"].value = '';
				}
			}
		});
	}

	show_fields(targets) {
		let self = this;
		targets.forEach((e) => {
			if (self["has" + self.capitalizeFirstLetter(e) + "DivTarget"]) {
				self.domShow(self[e + "DivTarget"])
			}
		});
	}

	person_affected() {
		return this.peopleAffectedInputTarget.checked;
	}

	affected_person_type() {
		let affectedValue = null, affectedText = null;

		if (!this.person_affected()) {
			return { value: affectedValue, text: affectedText };
		}

		this.affectedPersonTypeInputTargets.some((el) => {
			if (el.checked) {
				affectedValue = el.value;
				affectedText = el.nextSibling.nodeValue;
			}

			return el.checked;
		})

		return { value: affectedValue, text: affectedText };
	}

	update_field_visibility() {
		this.affectedPersonsVisibility();
		this.contractorVisibility();
		this.injuryVisibility();
		this.implicationsVisibility();
	}

	mutate_field_labels() {
		const $occupationLabel = this.affectedPersonOccupationInputTarget.labels[0];
		let newLabel = "Reason for being on site";

		switch (this.affected_person_type().text) {
			case "Staff member":
				newLabel = "Job role";
				break;
			case "Contractor":
				newLabel = "Job role or reason for being on site";
				break;
			case "Visitor":
			case "General Public":
				newLabel = "Reason for being on site";
				break;
			case "Pupil":
			case "Student":
				newLabel = "Year group, form, or class";
				break;
		}
		$occupationLabel.innerText = newLabel;
	}

	enabledForVisibility(targets, enabledForNames) {
		let enabledFors = {}

		enabledForNames.forEach(e => {
			let datasets = this[e + "InputTarget"].dataset["enabledFor"];

			if (datasets == undefined) {
				enabledFors[e] = []
			} else {
				enabledFors[e] = JSON.parse(datasets)
			}
		});

		let matchingTargets = Object.entries(enabledFors).filter(([_, v]) => v.includes(this.affected_person_type()?.value)).map((e) => { return e[0] })

		return {
			visibleTargets: targets.concat(matchingTargets),
			allTargets: targets.concat(enabledForNames),
			matchingTargets: matchingTargets,
			missingTargets: enabledForNames.filter(x => !matchingTargets.includes(x))
		}
	}

	affectedPersonsVisibility() {

		if (this.person_affected()) {
			this.show_fields(['affectedPersonType']);
			this.mutate_field_labels();
			this.domShow(this.affectedPersonsFormBlockTarget);
		} else {
			this.hide_fields(['affectedPersonType']);
			this.domHide(this.affectedPersonsFormBlockTarget);
		}

		let targets = [
			'injuryOccurred',
			'affectedPersonOutcome',
			"contactedNextOfKin"
		];

		let enabledForNames = [
			'affectedPersonName',
			'affectedPersonAddress',
			'affectedPersonContact',
			'affectedPersonOccupation'
		]

		let enabledForTargets = this.enabledForVisibility(targets, enabledForNames);

		if (this.affected_person_type()?.value) {
			this.domShow(this.injuryFormBlockTarget);
			this.domShow(this.implicationsFormBlockTarget);
			this.show_fields(enabledForTargets.visibleTargets);
			this.show_fields(targets);
			this.hide_fields(enabledForTargets.missingTargets);
		} else {
			this.domHide(this.injuryFormBlockTarget);
			this.domHide(this.implicationsFormBlockTarget);
			this.hide_fields(enabledForTargets.allTargets);
		}
	}

	contractorVisibility() {
		let targets = ["contractorInvolved"];
		if (this.affected_person_type()?.value === 'Contractor') {
			this.show_fields(targets);
		} else {
			this.hide_fields(targets);
		}
	}

	injuryVisibility() {
		let targets = [
			'injuryBodyPart',
			'injuryDescription',
			'treatmentDescription'
		];

		let enabledForNames = [
			'injuryType',
			'treatmentType',
		]

		let enabledForTargets = this.enabledForVisibility(targets, enabledForNames);

		if (this.injuryOccurredInputTarget.checked) {
			this.show_fields(enabledForTargets.visibleTargets);
			this.show_fields(targets);
			this.hide_fields(enabledForTargets.missingTargets);
		} else {
			this.hide_fields(enabledForTargets.allTargets);
		}
	}

	implicationsVisibility() {
		let targets = ["nextOfKinDetails"];
		if (this.contactedNextOfKinInputTarget.checked) {
			this.show_fields(targets);
		} else {
			this.hide_fields(targets);
		}
	}

	update_field_visibility() {
		this.affectedPersonsVisibility();
		this.contractorVisibility();
		this.injuryVisibility();
		this.implicationsVisibility();
		this.setupAutocomplete();
	}

	// Autocomplete
	setupAutocomplete() {
		const personType = this.affected_person_type().text
		if (personType === "Staff member") {
			this.affectedPersonsFormBlockTarget.setAttribute('data-autocomplete-url-value', this.staffAutocompleteUrlValue)
		} else if (personType === "Pupil") {
			this.affectedPersonsFormBlockTarget.setAttribute('data-autocomplete-url-value', this.pupilAutocompleteUrlValue)
		} else {
			this.affectedPersonsFormBlockTarget.removeAttribute('data-autocomplete-url-value')
		}
	}


}
